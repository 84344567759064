* {
    font-size: inherit; // Prevent some user agent weird font-size (e.g. textarea in Chrome)
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Microsoft Yahei", sans-serif;
    font-size: 16px;
}

html,
body,
#root,
.app {
    height: 100%; // on iOS, if the html height is less than window height, absolute-positioned elements cannot be seen.
}

.app {
    display: flex;
    flex-direction: column;
    .page {
        // flex: auto;
        // min-height: 0;
        // don't add overflow: auto here because we want the <HTML> to scroll so that we can use <ScrollRestoration>
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input,
textarea,
select,
button,
.btn {
    font-family: inherit;
    outline: 0;
    transition: box-shadow var(--anim-duration-short) ease-in-out;
    &:focus {
        box-shadow: 0 0 0 3px var(--focus-box-shadow-color);
    }
}

$color-brand: #007cdc;
$color-disabled: #888;
$color-grey: #828282;
$color-red: #f05458;

:root {
    // Animation
    --anim-duration-short: 0.15s;
    --anim-easing: ease-in-out;
    // Space
    --space-xs: 0.25em;
    --space-sm: 0.5em;
    --space-md: 1em;
    --space-lg: 1.5em;
    --space-xl: 2em;
    --space-xxl: 2.5em;
    // Color
    --small-font-size: 0.8em;
    --color-grey: #{$color-grey};
    --color-light-grey: #dfdfdf;
    --color-secondary-text: #828282;
    --color-border: #e8e8e8;
    --color-pre-text: #336699;
    --background-color: #f0f2f4;
    --default-border-radius: 0.3em;
    --color-link: #3194d0;
    --input-border-color: #bdc7d8;
    --input-focus-border-color: #80bdff;
    --input-focus-box-shadow-color: rgba(0, 123, 255, 0.25);
    --color-pill-background: #f4f4f4;
    --focus-box-shadow-color: rgba(0, 123, 255, 0.5);
    --color-page-background: #fff;
    --color-like-text: #{$color-red};
    --color-brand: #{$color-brand};
    --color-active-pill-background: #{$color-brand};
    --color-tab-button-background: #f4f4f4;
    --color-tab-button-active-background: #fff;
    --color-btn-primary-text: #fff;
    --color-disabled: #{$color-disabled};
    --color-error: #{$color-red};
    --color-red: #{$color-red};
    --color-upload-background: #80bdff;
    --color-upload-text: #fff;
    --color-upload-dash: #fff;
    --color-upload-background-hover: #{darken(#80bdff, 10%)};
    // Z-index
    --float-button-z-index: 99;
}

button {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: var(--space-sm) var(--space-md);
}

.flex-horizontal {
    display: flex;
    flex-direction: row;
}

.flex-vertical {
    display: flex;
    flex-direction: column;
}

.flex-hv-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-auto {
    flex: auto;
    min-height: 0;
    min-width: 0;
}

.flex-grow {
    flex: 1;
    min-width: 0;
    min-height: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-link);
}

.card {
    border: solid 1px var(--color-border);
    border-radius: var(--default-border-radius);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
    background-color: var(--card-background-color);
    padding: var(--space-md);
}

.absolute-hv-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn,
button {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: var(--space-sm) var(--space-md);

    &.btn-default {
        border: 1px solid var(--color-light-grey);
        border-radius: 2em;
        background-color: transparent;
        color: var(--color-secondary-text);
        transition: color var(--anim-duration-short) ease-in-out,
            background-color var(--anim-duration-short) ease-in-out;

        &:not(:disabled),
        &:not(.disabled) {
            :hover {
                color: var(--text-color);
                background-color: var(--color-light-grey);
            }
        }
    }

    &.btn-primary {
        border-radius: var(--space-xl);
        background-color: var(--color-brand);
        color: var(--color-btn-primary-text);

        &.disabled,
        &:disabled {
            background-color: var(--color-disabled);
            cursor: default;
        }
    }

    &.btn-icon-only {
        width: 2em;
        height: 2em;
        padding: 0;
        border-radius: 0;
        color: var(--color-secondary-text);
    }

    &.btn-block {
        display: block;
        width: 100%;
    }
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    padding: var(--space-sm);
    border: 1px solid var(--input-border-color);
    border-radius: var(--default-border-radius);

    &:focus {
        border-color: var(--input-focus-border-color);
        box-shadow: 0 0 0 var(--space-xs) var(--input-focus-box-shadow-color);
    }
}

// Loading
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.loading-spinner {
    position: relative;
    width: 1em;
    height: 1em;
    display: inline-block;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin-top: -50%;
        margin-left: -50%;
        border-radius: 50%;
        border: 0.05em solid var(--color-border);
        border-top-color: var(--color-brand);
        animation: spin 0.8s linear infinite;
    }
}

.loading-msg {
    @extend .flex-vertical;
    align-items: center;
    color: var(--color-secondary-text);
    .loading-msg-title {
        font-size: 1.5em;
        padding-bottom: var(--space-md);
    }
    .loading-spinner {
        font-size: 2em;
    }
}

label.file-uploader {
    position: relative;
    display: block;
    height: 10em;
    background-color: var(--color-upload-background);
    outline: var(--color-upload-dash) 1px dashed;
    outline-offset: calc(var(--space-sm) * -1);
    transition: background-color var(--anim-duration-short) ease-in-out;

    &:hover {
        background-color: var(--color-upload-background-hover);
    }

    input[type="file"],
    .file-uploader-msg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    input[type="file"] {
        opacity: 0;
        cursor: pointer;
        display: block;
    }

    .file-uploader-msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--color-upload-text);
    }
}
